import { DialogService }              from 'aurelia-dialog';
import { bindable, inject }           from 'aurelia-framework';
import { I18N }                       from 'aurelia-i18n';
import { activationStrategy }         from 'aurelia-router';
import { BaseOrderedListViewModel }   from 'base-ordered-list-view-model';
import { FilterFormSchema }           from 'modules/bituminous-mixtures/mixtures/bm-tests/filter-form-schema';
import { BMTestsRepository }          from 'modules/bituminous-mixtures/mixtures/bm-tests/services/repository';
import { CommentsListingModal }       from 'modules/core/custom-components/comments/listing-modal';
import { AdditionalInfoListingModal } from 'modules/core/listing-modals/additional-info-listing-modal';
import { InfoDialog }                 from 'resources/elements/html-elements/dialogs/info-dialog';
import { AppContainer }               from 'resources/services/app-container';
import { Downloader }                 from 'resources/services/downloader';

@inject(AppContainer, BMTestsRepository, I18N, Downloader, FilterFormSchema, DialogService)
export class ListBMTests extends BaseOrderedListViewModel {

    @bindable headerTitle;
    @bindable newRecordRoute;
    @bindable repository;
    @bindable datatable;

    constructor(appContainer, repository, i18N, downloader, filterFormSchema, dialogService) {
        super(appContainer);

        this.repository       = repository;
        this.i18N             = i18N;
        this.downloader       = downloader;
        this.filterFormSchema = filterFormSchema;
        this.dialogService    = dialogService;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return this.appContainer.globalConfig.defaultListView;
    }

    /**
     * Determines activation strategy
     *
     * @return {'replace'}
     */
    determineActivationStrategy() {
        return activationStrategy.replace;
    }

    /**
     * Handles canActivate event
     *
     * @returns {Promise.<*>|Promise}
     */
    async canActivate() {
        this.repository.setType(this.type);
        this.routePrefix      = `bituminous-mixtures.mixtures.${this.type}`;
        this.permissionPrefix = this.routePrefix;
        this.listingId        = `bituminous-mixtures.mixtures-${this.type}-listing`;
        this.headerTitle      = `listing.${this.routePrefix}`;
        this.newRecordRoute   = `${this.routePrefix}.create`;

        if (typeof this.type === 'undefined' || typeof this.sectorId === 'undefined') {
            console.error('BM Test type and sector are undefined');
            return false;
        }

        return this.redirectToSelectLotIntervention(`${this.routePrefix}.index`) && super.can([
            this.permissionPrefix + '.manage',
            this.permissionPrefix + '.view',
        ]);
    }

    /**
     * Handles activate event
     */
    async activate() {
        super.activate();

        this.authUser = this.appContainer.authenticatedUser;

        if (this.authUser.isLotClosed) {
            this.newRecordRoute = null;
        }

        this.selectedLotInformation = this.authUser.getAllSelectedLotInformation();

        // If user is builder, this will restrict the listings they have access to
        this.lot_intervention_id = (this.authUser.isBuilder) ? this.selectedLotInformation.lot_intervention_id : null;

        this.filterModel  = this.filterFormSchema.model(this);
        this.filterSchema = await this.filterFormSchema.schema(this, this.authUser.isBuilder);

        // Default filter by the current lot_intervention_id
        if (!this.filterModel.lot_intervention_id) {
            this.filterModel.lot_intervention_id = this.selectedLotInformation.lot_intervention_id;
        }

        this.defineDatatable();
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            repository:         {
                search:          (criteria) => this.repository.search(this.sectorId, criteria),
                destroy:         (id) => this.repository.destroy(id),
                destroySelected: (ids) => this.repository.destroySelected(ids),
            },
            show:               {
                action:  (row) => this.appContainer.router.navigateToRoute(this.routePrefix + '.view', { category: this.category, id: row.id }),
                visible: (row) => this.authUser.can([this.permissionPrefix + '.manage', this.permissionPrefix + '.view']),
            },
            edit:               {
                action:  (row) => this.appContainer.router.navigateToRoute(this.routePrefix + '.edit', { category: this.category, id: row.id }),
                visible: (row) => this.authUser.can([this.permissionPrefix + '.manage', this.permissionPrefix + '.edit'])
                    && !row.is_validated,
            },
            destroy:            {
                action:  (row) => this.repository.destroy(row.id),
                visible: (row) => this.authUser.can([this.permissionPrefix + '.manage', this.permissionPrefix + '.delete']),
            },
            options:            [
                {
                    label:   'form.button.validate-selected',
                    icon:    'icon-checkmark3',
                    action:  () => this.ensureSelectedRows(() => {
                        this.callThenResponse(this.repository.validateSelected(this.datatable.instance.selectedRows))
                    }),
                    visible: () => this.authUser.can([this.permissionPrefix + '.manage', this.permissionPrefix + '.validate']),
                },
                {
                    label:   'form.button.verify-selected',
                    icon:    'icon-checkmark3',
                    action:  () => this.ensureSelectedRows(() => {
                        this.callThenResponse(this.repository.verifySelected(this.datatable.instance.selectedRows))
                    }),
                    visible: () => this.canVerify() && this.authUser.can([this.permissionPrefix + '.manage', this.permissionPrefix + '.verify']),
                },
                {
                    label:   'form.button.invalidate-selected',
                    icon:    'icon-cross',
                    action:  () => this.ensureSelectedRows(() => {
                        this.callThenResponse(this.repository.invalidateSelected(this.datatable.instance.selectedRows))
                    }),
                    visible: () => this.authUser.can([this.permissionPrefix + '.manage', this.permissionPrefix + '.invalidate']),
                },
                {
                    label:   'form.button.export-zip',
                    icon:    'icon-file-zip',
                    action:  () => this.exportZip(),
                    visible: () => this.authUser.can([this.permissionPrefix + '.manage', this.permissionPrefix + '.view']),
                },
            ],
            actions:            [
                {
                    icon:    'icon-cross',
                    action:  (row) => this.callThenResponse(this.repository.invalidate(row.id)),
                    tooltip: 'form.button.invalidate',
                    visible: (row) => row.validatable && this.appContainer.authenticatedUser.can([this.permissionPrefix + '.manage', this.permissionPrefix + '.invalidate'])
                        && row.is_validated && !this.authUser.isBuilder && this.appContainer.authenticatedUser.inSameLotIntervention(row.lot_intervention_id),
                },
                {
                    icon:    'icon-checkmark3',
                    action:  (row) => this.callThenResponse(this.repository.validate(row.id)),
                    tooltip: 'form.button.validate',
                    visible: (row) => row.validatable && this.appContainer.authenticatedUser.can([this.permissionPrefix + '.manage', this.permissionPrefix + '.validate'])
                        && !row.is_validated && this.appContainer.authenticatedUser.inSameLotIntervention(row.lot_intervention_id),
                },
                {
                    icon:    'icon-checkmark3',
                    action:  (row) => this.callThenResponse(this.repository.verify(row.id)),
                    tooltip: 'form.button.verify',
                    visible: (row) => this.appContainer.authenticatedUser.can([this.permissionPrefix + '.manage', this.permissionPrefix + '.verify'])
                        && this.canVerify(row) && this.appContainer.authenticatedUser.inSameLotIntervention(row.lot_intervention_id),
                },
                {
                    icon:    'icon-info3',
                    action:  (row) => this.openModal({ relatableId: row.test_header_id }, AdditionalInfoListingModal),
                    tooltip: 'form.button.view-additional-info',
                },
                {
                    icon:    'icon-comments',
                    action:  (row) => this.openModal({ relatableId: row.test_header_id }, CommentsListingModal),
                    tooltip: 'form.button.view-comments',
                },
            ],
            actionsContextMenu: true,
            selectable:         true,
            destroySelected:    true,
            sorting:            [
                {
                    column:    0,
                    direction: 'desc',
                },
                {
                    column:    3,
                    direction: 'desc',
                },
            ],
            columns:            this.columns(),
        };
    }

    columns() {
        return [];
    }

    /**
     * Executes a given action if it is selected at least one row
     *
     * @param action
     * @returns {*}
     */
    ensureSelectedRows(action) {
        if (!this.datatable.instance.selectedRows.length) {
            return this.dialogService.open({
                viewModel: InfoDialog,
                model:     {
                    body:  this.appContainer.i18n.tr('message.select-at-least-one-record'),
                    title: this.appContainer.i18n.tr('text.attention'),
                },
            });
        }

        action();
    }

    /**
     * Call promise then show response notice and reload datatable
     * @param call
     */
    callThenResponse(call) {
        call.then(response => {
            this.appContainer.notifier.responseNotice(response);
            this.datatable.instance.reload();
        });
    }

    /**
     * Opens the given modal
     */
    openModal(model, viewModel) {
        return this.dialogService
            .open({ viewModel: viewModel, model: model })
            .whenClosed((response) => {
                if (!response.wasCancelled) {
                    this.datatable.instance.reload();
                }
            });
    }

    /**
     * Can the authenticated user verify?
     * @param row
     * @returns boolean
     */
    canVerify(row = false) {
        return (row && row.is_validated
                && ((this.authUser.isRegulator
                        && row.verified_inspection_at === null && row.verified_inspection_by === null)
                    || (this.authUser.isHighwayOperator
                        && row.verified_concessionary_at === null && row.verified_concessionary_by === null)))
            || (!row && (this.authUser.isRegulator || this.authUser.isHighwayOperator));
    }

    /**
     * Export ZIP
     *
     * @returns {*}
     */
    exportZip() {
        const filterModel = Object.assign(this.filterModel);

        filterModel.selectedRows = this.datatable.instance.selectedRows;
        filterModel.sector_id    = this.sectorId;

        return this.repository
            .generateZip(filterModel)
            .then(response => {
                response.status === true
                    ? this.appContainer.notifier.successNotice(this.i18N.tr('text.success-message.file-being-generated-please-wait'))
                    : this.appContainer.notifier.dangerNotice(response.message);
            });
    }

    /**
     * Exports to PDF and downloads
     *
     * @param row
     * @returns {*}
     */
    exportToPdf(row) {
        let fileName = this.appContainer.i18n.tr(this.headerTitle) + ' - ' + this.appContainer.i18n.tr('form.field.cover-page');
        return this.repository.exportToPdf(row.id)
            .then(
                response => this.downloader.download(response, fileName, 'pdf'),
            );
    }

    /**
     * Processed cell for Entity (Team)
     *
     * @param row
     * @returns {*}
     */
    getEntityWithTeam(row) {

        let html = row['entity_name'];
        html += '<small><br />(';
        html += row['team_name'];
        html += ')</small>';

        return html;
    }
}
